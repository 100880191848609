export const PAYMENT_MODE_ON_ARRIVAL = "onArrival";
export const PAYMENT_MODE_OPTIONAL = "optional";
export const PAYMENT_MODE_MANDATORY = "mandatory";

export const PAYMENT_TYPE_MODE_ONE_TIME = "oneTime";
export const PAYMENT_TYPE_MODE_SUBSCRIPTION = "subscription";

export const PAYMENT_METHOD_CARD = "card";
export const PAYMENT_METHOD_SWISH = "swish";
export const PAYMENT_METHOD_INVOICE = "invoice";

export const SUBSCRIPTION_RECURRING_UNIT_MONTHLY = "monthly";
export const SUBSCRIPTION_RECURRING_UNIT_QUARTERLY = "quarterly";
export const SUBSCRIPTION_RECURRING_UNIT_YEARLY = "yearly";

export const SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING = "ongoing";
export const SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH = "dayOfMonth";
