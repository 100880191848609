import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as serviceCategoriesActions } from "../../../../redux/servicecategories/serviceCategoriesRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import SnackbarError from "../../../components/SnackbarError";
import ServiceSectionHeader from "../ServiceSectionHeader";
import ServiceCommonDetailsForm from "../ServiceCommonDetailsForm";
import MembershipBookingSettingsForm from "./MembershipBookingSettingsForm";
import ServiceCheckoutDetailsFormOld from "../ServiceCheckoutDetailsFormOld";
import ServiceCheckoutDetailsForm from "../ServiceCheckoutDetailsForm";
import {
    SERVICE_COLOR_DEFAULT,
    PAYMENT_MODE_ON_ARRIVAL,
    PAYMENT_MODE_OPTIONAL,
    SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH,
    OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME,
    OFFER_VALID_FOR_TYPE_ALL_SERVICES,
    OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION,
    PAYMENT_TYPE_MODE_SUBSCRIPTION,
    SERVICE_TYPE_MEMBERSHIP,
} from "../utils";

const schemaWithoutPaymentTemplate = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    description: yup.string(),
    category: yup.object().nullable(),
    color: yup.string(),
    discountPercentage: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    maxBookingMinutes: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />),
    validUntilValue: yup.number().when("validUntilType", (paymentMode) => {
        if (paymentMode === OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    validUntilTimeUnit: yup.string(),
    fixedEndTime: yup.number().when("validUntilType", (paymentMode) => {
        if (paymentMode !== OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME) {
            return yup
                .date()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .date()
                .transform((_, val) => (val ? new Date(val) : null))
                .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />)
                .typeError(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />);
        }
    }),
    activeLimit: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
    paymentMode: yup.string(),
    paymentTypeMode: yup.string(),
    price: yup.number().when("paymentMode", (paymentMode) => {
        if (paymentMode === PAYMENT_MODE_ON_ARRIVAL || paymentMode === PAYMENT_MODE_OPTIONAL) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    paymentMethods: yup.array().when("paymentMode", (paymentMode) => {
        if (paymentMode !== PAYMENT_MODE_ON_ARRIVAL) {
            return yup.array().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD_CHECKBOXES" />);
        }

        return yup.array();
    }),
    vat: yup.object(),
    currency: yup.object(),
    billingTemplate: yup.object(),
    subscriptionRecurringDayMode: yup.object(),
    subscriptionRecurringDayOfMonth: yup
        .number()
        .when("subscriptionRecurringDayMode", (subscriptionRecurringDayMode) => {
            if (subscriptionRecurringDayMode?.id !== SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH) {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
            } else {
                return yup
                    .number()
                    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                    .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />)
                    .max(31, <FormattedMessage id="FORM.ERROR.MAX" values={{ number: 31 }} />);
            }
        }),
    subscriptionNoticePeriodDays: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
    customerMessageMode: yup.string(),
    addonsMode: yup.string(),
    proxyUsersMode: yup.string(),
    emailDetails: yup.string(),
});

const schemaWithPaymentTemplate = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    description: yup.string(),
    category: yup.object().nullable(),
    color: yup.string(),
    discountPercentage: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    maxBookingMinutes: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />),
    validUntilValue: yup.number().when("validUntilType", (paymentMode) => {
        if (paymentMode === OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    validUntilTimeUnit: yup.string(),
    fixedEndTime: yup.number().when("validUntilType", (paymentMode) => {
        if (paymentMode !== OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME) {
            return yup
                .date()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .date()
                .transform((_, val) => (val ? new Date(val) : null))
                .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />)
                .typeError(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />);
        }
    }),
    activeLimit: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
    paymentMode: yup.string(),
    paymentTypeMode: yup.string(),
    price: yup.number().when("paymentMode", (paymentMode) => {
        if (paymentMode === PAYMENT_MODE_ON_ARRIVAL || paymentMode === PAYMENT_MODE_OPTIONAL) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    vat: yup.object(),
    currency: yup.object(),
    paymentTemplate: yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    customerMessageMode: yup.string(),
    addonsMode: yup.string(),
    proxyUsersMode: yup.string(),
    giftMode: yup.string(),
    emailDetails: yup.string(),
});

function ServicePageMembershipAdd({
    getServiceOptions,
    addMembership,
    clearServiceState,
    clearDisplayError,
    clearErrorState,
    location,
    getServiceCopy,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { membership, serviceOptions, isLoading, serviceToCopy } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);
    const [redirect, setRedirect] = useState(false);
    const profileId = profile.id;

    const intl = useIntl();
    const queryParams = new URLSearchParams(location.search);
    const copyServiceId = queryParams.get("copyServiceId");

    const formMethods = useForm({
        resolver: yupResolver(
            serviceOptions?.paymentTemplates?.length > 0 ? schemaWithPaymentTemplate : schemaWithoutPaymentTemplate
        ),
    });

    const { handleSubmit, watch, setValue } = formMethods;

    useEffect(() => {
        return () => {
            clearServiceState();
            clearErrorState();
        };
    }, [clearServiceState, clearErrorState]);

    useEffect(() => {
        getServiceOptions(profileId);
    }, [profileId, getServiceOptions]);

    useEffect(() => {
        if (copyServiceId) {
            getServiceCopy(copyServiceId, SERVICE_TYPE_MEMBERSHIP);
        }
    }, [copyServiceId, getServiceCopy]);

    useEffect(() => {
        if (serviceToCopy) {
            setValue("name", intl.formatMessage({ id: "SERVICE.COPY.NEW_NAME" }, { serviceName: serviceToCopy.name }));
        }
    }, [serviceToCopy, setValue, intl]);

    const onFormSubmit = (values) => {
        var membershipData = {
            name: values.name,
            description: values.description,
            categoryId: values?.category?.id,
            color: values.color || SERVICE_COLOR_DEFAULT,
            isValidForAllServices: values.validForType === OFFER_VALID_FOR_TYPE_ALL_SERVICES ? true : false,
            discountPercentage: values.discountPercentage,
            maxBookingMinutes: values.maxBookingMinutes,
            maxBookingMinutesPerUnit: values.maxBookingMinutes ? values?.maxBookingMinutesPerUnit?.id : null,
            validUntilType: values.validUntilType,
            validUntilValue: values.validUntilValue,
            validUntilTimeUnit: values.validUntilTimeUnit,
            fixedEndTime: values.fixedEndTime,
            activeLimit: values.activeLimit,
            paymentTypeMode: values.paymentTypeMode,
            price: values.price || 0,
            vatId: values.vat.id,
            currencyId: values.currency.id,
            paymentTemplateId: values?.paymentTemplate?.id,
            customerMessageMode: values.customerMessageMode || null,
            customerMessage: values.customerMessage,
            addonsMode: values.addonsMode || null,
            proxyUsersMode: values.proxyUsersMode || null,
            giftMode: values.giftMode || null,
            emailDetails: values.emailDetails,
        };

        if (serviceOptions.paymentTemplates.length === 0) {
            membershipData.paymentMode = values.paymentMode;
            membershipData.paymentMethods = values.paymentMethods;
            membershipData.billingTemplateId = values?.billingTemplate?.id;

            if (membershipData.paymentTypeMode === PAYMENT_TYPE_MODE_SUBSCRIPTION) {
                membershipData.subscriptionRecurringUnit = values.subscriptionRecurringUnit.id;
                membershipData.subscriptionRecurringDayMode = values.subscriptionRecurringDayMode.id;
                membershipData.subscriptionRecurringDayOfMonth = values.subscriptionRecurringDayOfMonth;
                membershipData.subscriptionNoticePeriodDays = values.subscriptionNoticePeriodDays;
            }
        }

        addMembership(profileId, membershipData, () => {
            setRedirect(true);
        });
    };

    if (redirect && membership) {
        return <Redirect to={`/services/${membership.id}/membership`} />;
    }

    const validUntilType = watch("validUntilType");

    const hasPaymentTemplates = serviceOptions?.paymentTemplates?.length > 0;

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-4">
                        <FormattedMessage id="SERVICE.MEMBERSHIP.CREATE.TITLE" />
                    </Card.Title>
                    <Card.Subtitle className="mb-0" class="body">
                        <FormattedMessage id="SERVICE.TYPE.MEMBERSHIP.DESCRIPTION" />
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body className="pt-0">
                    <FormProvider {...formMethods}>
                        <Form>
                            <ServiceSectionHeader intlId={"SERVICE.DETAILS.HEADER"} />
                            <ServiceCommonDetailsForm isAddingService={true} service={serviceToCopy} />

                            <ServiceSectionHeader intlId={"SERVICE.MEMBERSHIP.BOOKING_SETTINGS.TITLE"} />
                            <MembershipBookingSettingsForm service={serviceToCopy} />

                            <ServiceSectionHeader intlId={"SERVICE.PAYMENT.HEADER"} />

                            {serviceOptions && !hasPaymentTemplates && (
                                <ServiceCheckoutDetailsFormOld
                                    serviceOptions={serviceOptions}
                                    allowSubscription={
                                        validUntilType === OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION
                                    }
                                    forceSubscription={
                                        validUntilType === OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION
                                    }
                                    service={serviceToCopy}
                                />
                            )}

                            {serviceOptions && hasPaymentTemplates && (
                                <ServiceCheckoutDetailsForm serviceOptions={serviceOptions} service={serviceToCopy} />
                            )}

                            <div style={{ marginTop: "50px" }}>
                                <BootstrapSaveButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit(onFormSubmit)}
                                    label={<FormattedMessage id="COMMON.ADD" />}
                                ></BootstrapSaveButton>
                            </div>
                        </Form>
                    </FormProvider>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, {
    ...serviceActions,
    ...serviceCategoriesActions,
    ...errorActions,
})(ServicePageMembershipAdd);
