import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions as serviceCategoriesActions } from "../../../redux/servicecategories/serviceCategoriesRedux";
import { Form, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { useFormContext, Controller } from "react-hook-form";
import { PRICE_PER_UNIT_HOUR, getPricePerUnitOption } from "./utils";
import { ModeSettingDropdown, MODE_OPTIONAL, MODE_MANDATORY } from "../../components/dropdowns/ModeSettingDropdown";
import { PriceForm } from "../../components/price/PriceForm";

function ServiceCheckoutDetailsFormPaymentTemplate({
    serviceOptions,
    service,
    pricePerOccasion,
    pricePerUnits = [],
    defaultPricePerUnit,
    allowAddonsMode = true,
    allowPricingTemplates,
}) {
    const intl = useIntl();

    const { register, control, setValue, watch, errors } = useFormContext();

    useEffect(() => {
        if (service) {
            if (service.paymentTemplate) {
                setValue("paymentTemplate", getPaymentTemplateOption(service.paymentTemplate));
            }

            if (service.pricePerUnit) {
                setValue("pricePerUnit", getPricePerUnitOption(service.pricePerUnit, intl));
            }

            if (service.currency) {
                setValue("currency", getCurrencyOption(service.currency));
            }

            if (service.pricingTemplate) {
                setValue("pricingTemplate", getPricingTemplateOption(service.pricingTemplate));
            }

            if (service.vat) {
                setValue("vat", getVatOption(service.vat));
            }

            if (service.customerMessageMode) {
                setValue("customerMessageMode", service.customerMessageMode);
            }

            if (service.addonsMode) {
                setValue("addonsMode", service.addonsMode);
            }

            if (service.proxyUsersMode) {
                setValue("proxyUsersMode", service.proxyUsersMode);
            }

            if (service.giftMode) {
                setValue("giftMode", service.giftMode);
            }
        }
    }, [service, setValue, intl]);

    const customerMessageMode = watch("customerMessageMode");
    const pricePerUnit = watch("pricePerUnit");

    const paymentTypeMode = watch("paymentTypeMode");
    if (!paymentTypeMode) {
        register("paymentTypeMode");
    }

    if (pricePerUnits.length > 0 && !pricePerUnit) {
        register("pricePerUnit");
        const pricePerUnitDefault = defaultPricePerUnit
            ? getPricePerUnitOption(defaultPricePerUnit, intl)
            : pricePerUnits.length === 1
            ? getPricePerUnitOption(pricePerUnits[0], intl)
            : getPricePerUnitOption(PRICE_PER_UNIT_HOUR, intl);
        setValue("pricePerUnit", pricePerUnitDefault, { shouldValidate: true });
    }

    const vat = watch("vat");
    if (!vat?.id && serviceOptions?.vats && !service) {
        const defaultVat = serviceOptions.vats.find((x) => x.isDefault);
        setValue("vat", getVatOption(defaultVat), { shouldValidate: true });
    }

    const currency = watch("currency");
    if (!currency?.id && serviceOptions?.currencies && !service) {
        setValue("currency", getCurrencyOption(serviceOptions.currencies[0]), { shouldValidate: true });
    }

    let allPricingTemplates = serviceOptions?.pricingTemplates ? [...serviceOptions.pricingTemplates] : [];
    const pricingTemplate = watch("pricingTemplate");
    if (allowPricingTemplates) {
        // Add default null option first on pricing templates since its optional
        allPricingTemplates.unshift({
            id: null,
            name: intl.formatMessage({
                id: "SERVICE.PAYMENT.PRICING_TEMPLATE.APPOINTMENT.DEFAULT_OPTION",
            }),
        });

        if (!pricingTemplate) {
            register("pricingTemplate");
            setValue("pricingTemplate", getPricingTemplateOption(allPricingTemplates[0]), {
                shouldValidate: true,
            });
        }
    }

    let allPaymentTemplates = serviceOptions?.paymentTemplates ? [...serviceOptions.paymentTemplates] : [];
    const paymentTemplate = watch("paymentTemplate");
    if (!paymentTemplate) {
        register("paymentTemplate");

        if (allPaymentTemplates.length === 1) {
            setValue("paymentTemplate", getPaymentTemplateOption(allPaymentTemplates[0]), {
                shouldValidate: true,
            });
        }
    }

    return (
        <div>
            <PriceForm
                priceTitle={
                    !pricePerOccasion ? (
                        <FormattedMessage id="SERVICE.PAYMENT.PRICE" />
                    ) : (
                        <FormattedMessage id="SERVICE.PAYMENT.PRICE_PER_OCCASION" />
                    )
                }
                priceDescription={<FormattedMessage id="SERVICE.PAYMENT.PRICE_DESCRIPTION" />}
                price={service?.price}
                pricePerUnits={pricePerUnits}
                pricePerUnit={pricePerUnit}
                vats={serviceOptions?.vats}
                vat={vat}
                currencies={serviceOptions?.currencies}
                currency={currency}
            />
            <Form.Row>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.PAYMENT.PAYMENT_TEMPLATE" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="paymentTemplate"
                        defaultValue={null}
                        render={() => (
                            <div>
                                <Typeahead
                                    id="typeahead-payment-payment-template"
                                    labelKey={"name"}
                                    onChange={(paymentTemplates) =>
                                        setValue("paymentTemplate", paymentTemplates[0], {
                                            shouldValidate: true,
                                        })
                                    }
                                    options={allPaymentTemplates.map((bt) => getPaymentTemplateOption(bt))}
                                    selected={paymentTemplate ? [paymentTemplate] : []}
                                    filterBy={() => true}
                                    inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                    isInvalid={errors.paymentTemplate ? true : false}
                                />
                            </div>
                        )}
                    />
                    {errors.paymentTemplate && (
                        <div className="text-danger" style={{ fontSize: "0.9rem", marginTop: "0.25rem" }}>
                            <FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />
                        </div>
                    )}
                    <Form.Text className="text-muted">
                        <FormattedMessage id="SERVICE.PAYMENT.PAYMENT_TEMPLATE.DESCRIPTION" />
                    </Form.Text>
                </Form.Group>
                {allowPricingTemplates && (
                    <Form.Group as={Col} xl={4}>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.PAYMENT.PRICING_TEMPLATE.APPOINTMENT" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="pricingTemplate"
                            defaultValue={null}
                            render={() => (
                                <div>
                                    <Typeahead
                                        id="typeahead-payment-pricing-template"
                                        labelKey={"name"}
                                        onChange={(pricingTemplates) =>
                                            setValue("pricingTemplate", pricingTemplates[0], { shouldValidate: true })
                                        }
                                        options={allPricingTemplates.map((bt) => getPricingTemplateOption(bt))}
                                        selected={pricingTemplate ? [pricingTemplate] : []}
                                        filterBy={() => true}
                                        inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                    />
                                </div>
                            )}
                        />
                        <Form.Text className="text-muted">
                            <FormattedMessage id="SERVICE.PAYMENT.PRICING_TEMPLATE.APPOINTMENT.DESCRIPTION" />
                        </Form.Text>
                    </Form.Group>
                )}
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.PAYMENT.GIFT_MODE" />
                    </Form.Label>
                    <ModeSettingDropdown name={"giftMode"} register={register} />
                    <Form.Text className="text-muted">
                        <FormattedMessage id="SERVICE.PAYMENT.GIFT_MODE_DESCRIPTION" />
                    </Form.Text>
                </Form.Group>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.PAYMENT.PROXY_USERS_MODE" />
                    </Form.Label>
                    <ModeSettingDropdown name={"proxyUsersMode"} register={register} includeDefaultGlobal={true} />
                    <Form.Text className="text-muted">
                        <FormattedMessage id="SERVICE.PAYMENT.PROXY_USERS_MODE_DESCRIPTION" />
                    </Form.Text>
                </Form.Group>
                {allowAddonsMode && (
                    <Form.Group as={Col} xl={4}>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.PAYMENT.ADDONS_MODE" />
                        </Form.Label>
                        <ModeSettingDropdown name={"addonsMode"} register={register} includeDefaultGlobal={true} />
                    </Form.Group>
                )}
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.PAYMENT.CUSTOMER_MESSAGE_MODE" />
                    </Form.Label>
                    <ModeSettingDropdown name={"customerMessageMode"} register={register} includeDefaultGlobal={true} />
                </Form.Group>
            </Form.Row>
            {(customerMessageMode === MODE_OPTIONAL || customerMessageMode === MODE_MANDATORY) && (
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.PAYMENT.CUSTOMER_MESSAGE" />
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        className="form-control form-control-lg col-xl-8"
                        name="customerMessage"
                        ref={register}
                        defaultValue={service?.customerMessage}
                    />
                </Form.Group>
            )}
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.PAYMENT.EMAIL_DETAILS" />
                </Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    className="form-control form-control-lg col-xl-8"
                    name="emailDetails"
                    ref={register}
                    defaultValue={service?.emailDetails}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage
                        id="SERVICE.PAYMENT.EMAIL_DETAILS_DESCRIPTION"
                        values={{
                            p: (...chunks) => <p>{chunks}</p>,
                            a: (msg) => (
                                <Link to={`/settings/bookings`} key={1}>
                                    <FormattedMessage id={"PROFILE.BOOKING_SETTINGS.TITLE"} />
                                </Link>
                            ),
                        }}
                    />
                </Form.Text>
            </Form.Group>
        </div>
    );
}

function getVatOption(vat) {
    return { id: vat.id, name: `${vat.amount}%` };
}

function getCurrencyOption(currency) {
    return { id: currency.id, name: currency.shortName };
}

function getPricingTemplateOption(pricingTemplate) {
    return { id: pricingTemplate.id, name: pricingTemplate.name };
}

function getPaymentTemplateOption(paymentTemplate) {
    return { id: paymentTemplate.id, name: paymentTemplate.name };
}

export default connect(null, serviceCategoriesActions)(ServiceCheckoutDetailsFormPaymentTemplate);
