import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as serviceCategoriesActions } from "../../../../redux/servicecategories/serviceCategoriesRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import SnackbarError from "../../../components/SnackbarError";
import ServiceSectionHeader from "../ServiceSectionHeader";
import ServiceCommonDetailsForm from "../ServiceCommonDetailsForm";
import CourseBookingSettingsForm from "./CourseBookingSettingsForm";
import CourseOccasionAddForm from "./CourseOccasionAddForm";
import ServiceCheckoutDetailsFormOld from "../ServiceCheckoutDetailsFormOld";
import ServiceCheckoutDetailsForm from "../ServiceCheckoutDetailsForm";
import { PAYMENT_MODE_ON_ARRIVAL, PAYMENT_MODE_OPTIONAL } from "../utils";
import moment from "moment";
import { SERVICE_COLOR_DEFAULT, SERVICE_TYPE_COURSE } from "../utils";

const schemaWithoutPaymentTemplate = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    description: yup.string(),
    category: yup.object().nullable(),
    color: yup.string(),
    bookableFrom: yup
        .date()
        .transform((_, val) => (val ? new Date(val) : null))
        .when("bookableTo", (bookableTo, schema) => {
            if (bookableTo) {
                return schema.max(new Date(bookableTo), <FormattedMessage id="FORM.ERROR.DATE_START_BEFORE_END" />);
            }
        })
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />)
        .typeError(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    bookableTo: yup
        .date()
        .transform((_, val) => (val ? new Date(val) : null))
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />)
        .typeError(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    resources: yup.array().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    maxAttendees: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />)
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    occasions: yup.array().required(<FormattedMessage id="SERVICE.COURSE.OCCASIONS.ERROR.AT_LEAST_ONE" />),
    paymentMode: yup.string(),
    price: yup.number().when("paymentMode", (paymentMode) => {
        if (paymentMode === PAYMENT_MODE_ON_ARRIVAL || paymentMode === PAYMENT_MODE_OPTIONAL) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    paymentMethods: yup.array().when("paymentMode", (paymentMode) => {
        if (paymentMode !== PAYMENT_MODE_ON_ARRIVAL) {
            return yup.array().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD_CHECKBOXES" />);
        }

        return yup.array();
    }),
    vat: yup.object(),
    currency: yup.object(),
    billingTemplate: yup.object(),
    customerMessageMode: yup.string(),
    addonsMode: yup.string(),
    proxyUsersMode: yup.string(),
    emailDetails: yup.string(),
});

const schemaWithPaymentTemplate = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    description: yup.string(),
    category: yup.object().nullable(),
    color: yup.string(),
    bookableFrom: yup
        .date()
        .transform((_, val) => (val ? new Date(val) : null))
        .when("bookableTo", (bookableTo, schema) => {
            if (bookableTo) {
                return schema.max(new Date(bookableTo), <FormattedMessage id="FORM.ERROR.DATE_START_BEFORE_END" />);
            }
        })
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />)
        .typeError(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    bookableTo: yup
        .date()
        .transform((_, val) => (val ? new Date(val) : null))
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />)
        .typeError(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    resources: yup.array().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    maxAttendees: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />)
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    occasions: yup.array().required(<FormattedMessage id="SERVICE.COURSE.OCCASIONS.ERROR.AT_LEAST_ONE" />),
    paymentMode: yup.string(),
    price: yup.number().when("paymentMode", (paymentMode) => {
        if (paymentMode === PAYMENT_MODE_ON_ARRIVAL || paymentMode === PAYMENT_MODE_OPTIONAL) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    vat: yup.object(),
    currency: yup.object(),
    paymentTemplate: yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    customerMessageMode: yup.string(),
    addonsMode: yup.string(),
    proxyUsersMode: yup.string(),
    giftMode: yup.string(),
    emailDetails: yup.string(),
});

function ServicePageCourseAdd({
    getServiceOptions,
    addCourse,
    clearServiceState,
    clearDisplayError,
    clearErrorState,
    location,
    getServiceCopy,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { course, serviceOptions, isLoading, serviceToCopy } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);
    const [redirect, setRedirect] = useState(false);
    const profileId = profile.id;

    const intl = useIntl();
    const queryParams = new URLSearchParams(location.search);
    const copyServiceId = queryParams.get("copyServiceId");

    const formMethods = useForm({
        resolver: yupResolver(
            serviceOptions?.paymentTemplates?.length > 0 ? schemaWithPaymentTemplate : schemaWithoutPaymentTemplate
        ),
    });

    const { handleSubmit, setValue } = formMethods;

    useEffect(() => {
        return () => {
            clearServiceState();
            clearErrorState();
        };
    }, [clearServiceState, clearErrorState]);

    useEffect(() => {
        getServiceOptions(profileId);
    }, [profileId, getServiceOptions]);

    useEffect(() => {
        if (copyServiceId) {
            getServiceCopy(copyServiceId, SERVICE_TYPE_COURSE);
        }
    }, [copyServiceId, getServiceCopy]);

    useEffect(() => {
        if (serviceToCopy) {
            setValue("name", intl.formatMessage({ id: "SERVICE.COPY.NEW_NAME" }, { serviceName: serviceToCopy.name }));
        }
    }, [serviceToCopy, setValue, intl]);

    const onFormSubmit = (values) => {
        var courseData = {
            name: values.name,
            description: values.description,
            categoryId: values?.category?.id,
            color: values.color || SERVICE_COLOR_DEFAULT,
            isBookPerOccasion: values.courseType === "bookPerOccasion",
            resourceIds: values.resources.map((x) => x.id),
            bookableFrom: moment(values.bookableFrom).format(),
            bookableTo: moment(values.bookableTo).format(),
            maxAttendees: values.maxAttendees,
            isWaitingListEnabled: values.isWaitingListEnabled,
            occasions: values.occasions.map((x) => {
                return {
                    startTime: moment(x.startTime).format(),
                    endTime: moment(x.endTime).format(),
                    repeatUntil: x.repeatUntil ? moment(x.repeatUntil).format() : null,
                };
            }),
            price: values.price || 0,
            vatId: values.vat.id,
            currencyId: values.currency.id,
            paymentTemplateId: values?.paymentTemplate?.id,
            cancellationTemplateId: values?.cancellationTemplate?.id,
            reminderTemplateId: values?.reminderTemplate?.id,
            customerMessageMode: values.customerMessageMode || null,
            customerMessage: values.customerMessage,
            addonsMode: values.addonsMode || null,
            proxyUsersMode: values.proxyUsersMode || null,
            giftMode: values.giftMode || null,
            emailDetails: values.emailDetails,
        };

        if (serviceOptions.paymentTemplates.length === 0) {
            courseData.paymentMode = values.paymentMode;
            courseData.paymentMethods = values.paymentMethods;
            courseData.billingTemplateId = values?.billingTemplate?.id;
        }

        addCourse(profileId, courseData, () => {
            setRedirect(true);
        });
    };

    if (redirect && course) {
        return <Redirect to={`/services/${course.id}/group-service`} />;
    }

    const courseType = formMethods.watch("courseType");

    const hasPaymentTemplates = serviceOptions?.paymentTemplates?.length > 0;

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-4">
                        <FormattedMessage id="SERVICE.COURSE.CREATE.TITLE" />
                    </Card.Title>
                    <Card.Subtitle className="mb-0" class="body">
                        <FormattedMessage id="SERVICE.TYPE.COURSE.DESCRIPTION" />
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body className="pt-0">
                    <FormProvider {...formMethods}>
                        <Form>
                            <ServiceSectionHeader intlId={"SERVICE.DETAILS.HEADER"} />
                            <ServiceCommonDetailsForm isAddingService={true} service={serviceToCopy} />

                            <ServiceSectionHeader intlId={"SERVICE.COURSE.BOOKING_SETTINGS.TITLE"} />
                            <CourseBookingSettingsForm
                                serviceOptions={serviceOptions}
                                service={serviceToCopy}
                                isAddingService={true}
                            />

                            <ServiceSectionHeader intlId={"SERVICE.COURSE.OCCASIONS.TITLE"} />
                            <CourseOccasionAddForm />

                            <ServiceSectionHeader intlId={"SERVICE.PAYMENT.HEADER"} />

                            {serviceOptions && !hasPaymentTemplates && (
                                <ServiceCheckoutDetailsFormOld
                                    serviceOptions={serviceOptions}
                                    pricePerOccasion={courseType === "bookPerOccasion"}
                                    service={serviceToCopy}
                                />
                            )}

                            {serviceOptions && hasPaymentTemplates && (
                                <ServiceCheckoutDetailsForm
                                    serviceOptions={serviceOptions}
                                    pricePerOccasion={courseType === "bookPerOccasion"}
                                    service={serviceToCopy}
                                />
                            )}

                            <div style={{ marginTop: "50px" }}>
                                <BootstrapSaveButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit(onFormSubmit)}
                                    label={<FormattedMessage id="COMMON.ADD" />}
                                ></BootstrapSaveButton>
                            </div>
                        </Form>
                    </FormProvider>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, {
    ...serviceActions,
    ...serviceCategoriesActions,
    ...errorActions,
})(ServicePageCourseAdd);
