import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as paymentTemplateActions } from "../../../redux/paymenttemplates/paymentTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import SnackbarError from "../../components/SnackbarError";
import PaymentTemplateDetailsForm from "./PaymentTemplateDetailsForm";
import {
    PAYMENT_MODE_ON_ARRIVAL,
    PAYMENT_TYPE_MODE_SUBSCRIPTION,
    SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH,
    PAYMENT_METHOD_SWISH,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_INVOICE,
} from "./utils";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    paymentMode: yup.string(),
    paymentTypeMode: yup.string(),
    paymentMethods: yup.array().when("paymentMode", (paymentMode) => {
        if (paymentMode !== PAYMENT_MODE_ON_ARRIVAL) {
            return yup.array().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD_CHECKBOXES" />);
        }

        return yup.array();
    }),
    billingTemplate: yup.object(),
    subscriptionRecurringDayMode: yup.object(),
    subscriptionRecurringDayOfMonth: yup
        .number()
        .when("subscriptionRecurringDayMode", (subscriptionRecurringDayMode) => {
            if (subscriptionRecurringDayMode?.id !== SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH) {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
            } else {
                return yup
                    .number()
                    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                    .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />)
                    .max(31, <FormattedMessage id="FORM.ERROR.MAX" values={{ number: 31 }} />);
            }
        }),
    subscriptionNoticePeriodDays: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
});

function PaymentTemplatePageAdd({
    addProfilePaymentTemplate,
    clearPaymentTemplateState,
    clearDisplayError,
    clearErrorState,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { paymentTemplate, isLoading } = useSelector((state) => state.paymentTemplates);
    const { displayError } = useSelector((state) => state.errors);
    const [redirect, setRedirect] = useState(false);
    const profileId = profile.id;

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    useEffect(() => {
        return () => {
            clearPaymentTemplateState();
            clearErrorState();
        };
    }, [clearPaymentTemplateState, clearErrorState]);

    const onFormSubmit = (values) => {
        var templateData = {
            name: values.name,
            isDefault: values.isDefault,
            paymentMode: values.paymentMode,
            billingTemplateId: values?.billingTemplate?.id,
        };

        if (values.paymentMethods) {
            templateData.isSwishEnabled = values.paymentMethods.includes(PAYMENT_METHOD_SWISH);
            templateData.isCardEnabled = values.paymentMethods.includes(PAYMENT_METHOD_CARD);
            templateData.isInvoiceEnabled = values.paymentMethods.includes(PAYMENT_METHOD_INVOICE);
        }

        if (values.paymentTypeMode === PAYMENT_TYPE_MODE_SUBSCRIPTION) {
            templateData.isRecurring = true;
            templateData.recurringUnit = values.recurringUnit.id;
            templateData.recurringDayMode = values.subscriptionRecurringDayMode.id;
            templateData.recurringDayOfMonth = values.subscriptionRecurringDayOfMonth;
            templateData.recurringNoticePeriodDays = values.subscriptionNoticePeriodDays;
        }

        addProfilePaymentTemplate(profileId, templateData, () => {
            setRedirect(true);
        });
    };

    if (redirect && paymentTemplate) {
        return <Redirect to={`/payment-templates/${paymentTemplate.id}`} />;
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-4">
                        <FormattedMessage id="PAYMENT_TEMPLATE.CREATE.TITLE" />
                    </Card.Title>
                    <Card.Subtitle className="mb-0" class="body">
                        <FormattedMessage id="PROFILE.PAYMENT_TEMPLATES.SUBTITLE" />
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <PaymentTemplateDetailsForm />
                            <div style={{ marginTop: "50px" }}>
                                <BootstrapSaveButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit(onFormSubmit)}
                                    label={<FormattedMessage id="COMMON.ADD" />}
                                ></BootstrapSaveButton>
                            </div>
                        </Form>
                    </FormProvider>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, {
    ...paymentTemplateActions,
    ...errorActions,
})(PaymentTemplatePageAdd);
