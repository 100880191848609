import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions as serviceCategoriesActions } from "../../../redux/servicecategories/serviceCategoriesRedux";
import { Form, Row, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { useFormContext, Controller } from "react-hook-form";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import MaterialRadioDescriptionButton from "../../components/inputs/MaterialRadioDescriptionButton";
import {
    PAYMENT_MODE_ON_ARRIVAL,
    PAYMENT_MODE_OPTIONAL,
    PAYMENT_MODE_MANDATORY,
    PAYMENT_TYPE_MODE_ONE_TIME,
    PAYMENT_TYPE_MODE_SUBSCRIPTION,
    PAYMENT_METHOD_SWISH,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_INVOICE,
    PRICE_PER_UNIT_HOUR,
    SUBSCRIPTION_RECURRING_UNIT_MONTHLY,
    SUBSCRIPTION_RECURRING_UNIT_QUARTERLY,
    SUBSCRIPTION_RECURRING_UNIT_YEARLY,
    SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING,
    SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH,
    getPricePerUnitOption,
} from "./utils";
import { ModeSettingDropdown, MODE_OPTIONAL, MODE_MANDATORY } from "../../components/dropdowns/ModeSettingDropdown";

function getVatOption(vat) {
    return { id: vat.id, name: `${vat.amount}%` };
}

function getCurrencyOption(currency) {
    return { id: currency.id, name: currency.shortName };
}

function getPricingTemplateOption(pricingTemplate) {
    return { id: pricingTemplate.id, name: pricingTemplate.name };
}

function getBillingTemplateOption(billingTemplate) {
    return { id: billingTemplate.id, name: billingTemplate.name };
}

function getSubscriptionRecurringUnitOption(id, intl) {
    if (id === SUBSCRIPTION_RECURRING_UNIT_MONTHLY) {
        return {
            id: SUBSCRIPTION_RECURRING_UNIT_MONTHLY,
            name: intl.formatMessage({
                id: "SUBSCRIPTION.RECURRING_UNIT.MONTHLY",
            }),
        };
    } else if (id === SUBSCRIPTION_RECURRING_UNIT_QUARTERLY) {
        return {
            id: SUBSCRIPTION_RECURRING_UNIT_QUARTERLY,
            name: intl.formatMessage({
                id: "SUBSCRIPTION.RECURRING_UNIT.QUARTERLY",
            }),
        };
    } else if (id === SUBSCRIPTION_RECURRING_UNIT_YEARLY) {
        return {
            id: SUBSCRIPTION_RECURRING_UNIT_YEARLY,
            name: intl.formatMessage({
                id: "SUBSCRIPTION.RECURRING_UNIT.YEARLY",
            }),
        };
    }
}

function getSubscriptionRecurringDayModeOption(id, intl) {
    if (id === SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING) {
        return {
            id: SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING,
            name: intl.formatMessage({
                id: "SERVICE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_DAY_MODE.ONGOING",
            }),
        };
    } else if (id === SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH) {
        return {
            id: SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH,
            name: intl.formatMessage({
                id: "SERVICE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_DAY_MODE.DAY_OF_MONTH",
            }),
        };
    }
}

const useStyles = makeStyles((theme) => ({
    multipleOptionsRb: {
        marginTop: "4px",
        marginBottom: "4px",
    },
}));

function ServiceCheckoutDetailsForm({
    serviceOptions,
    service,
    pricePerOccasion,
    pricePerUnits = [],
    defaultPricePerUnit,
    allowSubscription,
    forceSubscription,
    allowAddonsMode = true,
    allowPricingTemplates,
}) {
    const intl = useIntl();
    const classes = useStyles();

    const { register, control, setValue, watch, errors } = useFormContext();

    useEffect(() => {
        if (service) {
            setValue(
                "paymentMode",
                service.paymentMode === PAYMENT_MODE_ON_ARRIVAL
                    ? PAYMENT_MODE_ON_ARRIVAL
                    : service.paymentMode === PAYMENT_MODE_MANDATORY
                    ? PAYMENT_MODE_MANDATORY
                    : PAYMENT_MODE_OPTIONAL
            );

            if (service.pricePerUnit) {
                setValue("pricePerUnit", getPricePerUnitOption(service.pricePerUnit, intl));
            }

            if (service.currency) {
                setValue("currency", getCurrencyOption(service.currency));
            }

            if (service.pricingTemplate) {
                setValue("pricingTemplate", getPricingTemplateOption(service.pricingTemplate));
            }

            if (service.billingTemplate) {
                setValue("billingTemplate", getBillingTemplateOption(service.billingTemplate));
            }

            if (service.vat) {
                setValue("vat", getVatOption(service.vat));
            }

            if (service.subscriptionRecurringUnit) {
                setValue(
                    "subscriptionRecurringUnit",
                    getSubscriptionRecurringUnitOption(service.subscriptionRecurringUnit, intl)
                );
            }

            if (service.subscriptionRecurringDayMode) {
                setValue(
                    "subscriptionRecurringDayMode",
                    getSubscriptionRecurringDayModeOption(service.subscriptionRecurringDayMode, intl)
                );
            }

            if (service.subscriptionRecurringDayOfMonth) {
                setValue("subscriptionRecurringDayOfMonth", service.subscriptionRecurringDayOfMonth);
            }

            if (service.customerMessageMode) {
                setValue("customerMessageMode", service.customerMessageMode);
            }

            if (service.addonsMode) {
                setValue("addonsMode", service.addonsMode);
            }

            if (service.proxyUsersMode) {
                setValue("proxyUsersMode", service.proxyUsersMode);
            }
        }
    }, [service, setValue, intl]);

    const paymentMode = watch("paymentMode");
    const customerMessageMode = watch("customerMessageMode");
    const pricePerUnit = watch("pricePerUnit");

    const paymentTypeMode = watch("paymentTypeMode");
    if (!paymentTypeMode) {
        register("paymentTypeMode");
    }

    const subscriptionRecurringUnit = watch("subscriptionRecurringUnit");
    if (!subscriptionRecurringUnit) {
        register("subscriptionRecurringUnit");
    }

    const subscriptionRecurringDayMode = watch("subscriptionRecurringDayMode");
    if (!subscriptionRecurringDayMode) {
        register("subscriptionRecurringDayMode");
    }

    const subscriptionRecurringDayOfMonth = watch("subscriptionRecurringDayOfMonth");
    if (!subscriptionRecurringDayOfMonth) {
        register("subscriptionRecurringDayOfMonth");
    }

    if (pricePerUnits.length > 0 && !pricePerUnit) {
        register("pricePerUnit");
        const pricePerUnitDefault = defaultPricePerUnit
            ? getPricePerUnitOption(defaultPricePerUnit, intl)
            : pricePerUnits.length === 1
            ? getPricePerUnitOption(pricePerUnits[0], intl)
            : getPricePerUnitOption(PRICE_PER_UNIT_HOUR, intl);
        setValue("pricePerUnit", pricePerUnitDefault, { shouldValidate: true });
    }

    const vat = watch("vat");
    if (!vat?.id && serviceOptions?.vats && !service) {
        const defaultVat = serviceOptions.vats.find((x) => x.isDefault);
        setValue("vat", getVatOption(defaultVat), { shouldValidate: true });
    }

    const currency = watch("currency");
    if (!currency?.id && serviceOptions?.currencies && !service) {
        setValue("currency", getCurrencyOption(serviceOptions.currencies[0]), { shouldValidate: true });
    }

    let allPricingTemplates = serviceOptions?.pricingTemplates ? [...serviceOptions.pricingTemplates] : [];
    const pricingTemplate = watch("pricingTemplate");
    if (allowPricingTemplates) {
        // Add default null option first on pricing templates since its optional
        allPricingTemplates.unshift({
            id: null,
            name: intl.formatMessage({
                id: "SERVICE.PAYMENT.PRICING_TEMPLATE.APPOINTMENT.DEFAULT_OPTION",
            }),
        });

        if (!pricingTemplate) {
            register("pricingTemplate");
            setValue("pricingTemplate", getPricingTemplateOption(allPricingTemplates[0]), {
                shouldValidate: true,
            });
        }
    }

    // Add default null option first on billing templates since its optional
    let allBillingTemplates = serviceOptions?.billingTemplates ? [...serviceOptions.billingTemplates] : [];
    allBillingTemplates.unshift({
        id: null,
        name: intl.formatMessage({
            id: "SERVICE.PAYMENT.BILLING_TEMPLATE.DEFAULT_OPTION",
        }),
    });

    const billingTemplate = watch("billingTemplate");
    if (!billingTemplate) {
        register("billingTemplate");
        setValue("billingTemplate", getBillingTemplateOption(allBillingTemplates[0]), {
            shouldValidate: true,
        });
    }

    const allowSwish = serviceOptions?.paymentMethods?.findIndex((x) => x === PAYMENT_METHOD_SWISH) > -1;
    const allowCard = serviceOptions?.paymentMethods?.findIndex((x) => x === PAYMENT_METHOD_CARD) > -1;
    const allowInvoice = serviceOptions?.paymentMethods?.findIndex((x) => x === PAYMENT_METHOD_INVOICE) > -1;

    if (forceSubscription) {
        if (paymentTypeMode !== PAYMENT_TYPE_MODE_SUBSCRIPTION) {
            setValue("paymentMode", PAYMENT_MODE_MANDATORY);
            setValue("paymentTypeMode", PAYMENT_TYPE_MODE_SUBSCRIPTION);

            if (!subscriptionRecurringUnit) {
                setValue(
                    "subscriptionRecurringUnit",
                    getSubscriptionRecurringUnitOption(SUBSCRIPTION_RECURRING_UNIT_MONTHLY, intl)
                );
            }

            if (!subscriptionRecurringDayMode) {
                setValue(
                    "subscriptionRecurringDayMode",
                    getSubscriptionRecurringDayModeOption(SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING, intl)
                );
            }
        }
    } else if (!paymentTypeMode) {
        setValue("paymentTypeMode", PAYMENT_TYPE_MODE_ONE_TIME);
    }

    const setPaymentModeDependeableValuesToDefault = () => {
        setValue("paymentTypeMode", PAYMENT_TYPE_MODE_ONE_TIME);
        if (service?.billingTemplate) {
            setValue("billingTemplate", getBillingTemplateOption(service.billingTemplate));
        }
    };

    const shouldRecurringDayOfMonthBeLocked = (recurringUnitId, recurringDayModeId) => {
        return (
            (recurringUnitId === SUBSCRIPTION_RECURRING_UNIT_QUARTERLY ||
                recurringUnitId === SUBSCRIPTION_RECURRING_UNIT_YEARLY) &&
            recurringDayModeId === SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH
        );
    };

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.PAYMENT.MODE.TITLE" />
                </Form.Label>
                <Controller
                    control={control}
                    name="paymentMode"
                    defaultValue={PAYMENT_MODE_ON_ARRIVAL}
                    render={({ onChange, value }) => (
                        <>
                            <Row noGutters>
                                <Col sm={4} className={classes.multipleOptionsRb}>
                                    <MaterialRadioDescriptionButton
                                        checked={value === PAYMENT_MODE_ON_ARRIVAL}
                                        value={PAYMENT_MODE_ON_ARRIVAL}
                                        onChange={(e) => onChange(e)}
                                        titleIntlId={"SERVICE.PAYMENT.MODE.ON_ARRIVAL.TITLE"}
                                        descriptionIntlId={"SERVICE.PAYMENT.MODE.ON_ARRIVAL.DESCRIPTION"}
                                        disabled={forceSubscription}
                                        readOnly={forceSubscription}
                                    />
                                </Col>
                                <Col sm={4} className={classes.multipleOptionsRb}>
                                    <MaterialRadioDescriptionButton
                                        checked={value === PAYMENT_MODE_MANDATORY}
                                        value={PAYMENT_MODE_MANDATORY}
                                        onChange={(e) => {
                                            setPaymentModeDependeableValuesToDefault();
                                            onChange(e);
                                        }}
                                        titleIntlId={"SERVICE.PAYMENT.MODE.MANDATORY.TITLE"}
                                        descriptionIntlId={"SERVICE.PAYMENT.MODE.MANDATORY.DESCRIPTION"}
                                    />
                                </Col>
                                <Col sm={4} className={classes.multipleOptionsRb}>
                                    <MaterialRadioDescriptionButton
                                        checked={value === PAYMENT_MODE_OPTIONAL}
                                        value={PAYMENT_MODE_OPTIONAL}
                                        onChange={(e) => {
                                            setPaymentModeDependeableValuesToDefault();
                                            onChange(e);
                                        }}
                                        titleIntlId={"SERVICE.PAYMENT.MODE.OPTIONAL.TITLE"}
                                        descriptionIntlId={"SERVICE.PAYMENT.MODE.OPTIONAL.DESCRIPTION"}
                                        disabled={forceSubscription}
                                        readOnly={forceSubscription}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                />
            </Form.Group>
            {paymentMode && paymentMode !== PAYMENT_MODE_ON_ARRIVAL && (
                <>
                    {allowSubscription && (
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                <FormattedMessage id="SERVICE.PAYMENT.TYPE.TITLE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="paymentTypeMode"
                                defaultValue={
                                    !forceSubscription ? PAYMENT_TYPE_MODE_ONE_TIME : PAYMENT_TYPE_MODE_SUBSCRIPTION
                                }
                                render={({ onChange, value }) => (
                                    <>
                                        <Col xs={3}>
                                            <MaterialRadioDescriptionButton
                                                checked={value === PAYMENT_TYPE_MODE_ONE_TIME}
                                                value={PAYMENT_TYPE_MODE_ONE_TIME}
                                                onChange={(e) => onChange(e)}
                                                titleIntlId={"SERVICE.PAYMENT.TYPE.SINGLE_PAYMENT"}
                                                descriptionIntlId={"SERVICE.PAYMENT.TYPE.SINGLE_PAYMENT_DESCRIPTION"}
                                                disabled={forceSubscription}
                                                readOnly={forceSubscription}
                                            />
                                        </Col>
                                        <Col>
                                            <MaterialRadioDescriptionButton
                                                checked={value === PAYMENT_TYPE_MODE_SUBSCRIPTION}
                                                value={PAYMENT_TYPE_MODE_SUBSCRIPTION}
                                                onChange={(e) => onChange(e)}
                                                titleIntlId={"SERVICE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT"}
                                                descriptionIntlId={
                                                    "SERVICE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT_DESCRIPTION"
                                                }
                                            />
                                        </Col>
                                    </>
                                )}
                            />
                        </Form.Group>
                    )}
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                            <FormattedMessage id="SERVICE.PAYMENT.METHODS" />
                        </Form.Label>
                        <Col sm={10}>
                            <FormControlLabel
                                value={PAYMENT_METHOD_CARD}
                                control={
                                    <Checkbox
                                        disabled={!allowCard}
                                        defaultChecked={
                                            (allowCard && !service) ||
                                            (service && service?.paymentMethods?.includes("card"))
                                        }
                                    />
                                }
                                label={<FormattedMessage id="SERVICE.PAYMENT.METHOD.CARD" />}
                                name={"paymentMethods"}
                                inputRef={register}
                                style={{ marginLeft: "0px" }}
                            />
                            <FormControlLabel
                                value={PAYMENT_METHOD_INVOICE}
                                control={
                                    <Checkbox
                                        disabled={!allowInvoice}
                                        defaultChecked={
                                            (allowInvoice && !service) ||
                                            (service && service?.paymentMethods?.includes("invoice"))
                                        }
                                    />
                                }
                                label={<FormattedMessage id="SERVICE.PAYMENT.METHOD.INVOICE" />}
                                name={"paymentMethods"}
                                inputRef={register}
                            />
                            {paymentTypeMode === PAYMENT_TYPE_MODE_ONE_TIME && (
                                <FormControlLabel
                                    value={PAYMENT_METHOD_SWISH}
                                    control={
                                        <Checkbox
                                            disabled={!allowSwish}
                                            defaultChecked={
                                                (allowSwish && !service) ||
                                                (service && service?.paymentMethods?.includes("swish"))
                                            }
                                        />
                                    }
                                    label={<FormattedMessage id="SERVICE.PAYMENT.METHOD.SWISH" />}
                                    name={"paymentMethods"}
                                    inputRef={register}
                                />
                            )}
                        </Col>
                        {errors.paymentMethods?.message && (
                            <>
                                <Col sm={2}></Col>
                                <Col style={{ marginLeft: "12px" }}>
                                    <div className="text-danger" style={{ fontSize: "0.9rem", marginTop: "0.25rem" }}>
                                        {errors.paymentMethods.message}
                                    </div>
                                </Col>
                            </>
                        )}
                    </Form.Group>
                </>
            )}
            <Form.Row>
                <Form.Group as={Col} xl={3}>
                    <Form.Label>
                        {!pricePerOccasion ? (
                            <FormattedMessage id="SERVICE.PAYMENT.PRICE" />
                        ) : (
                            <FormattedMessage id="SERVICE.PAYMENT.PRICE_PER_OCCASION" />
                        )}
                    </Form.Label>
                    <Form.Control
                        type="number"
                        className="form-control form-control-lg"
                        name="price"
                        ref={register}
                        isInvalid={errors.price}
                        defaultValue={service?.price}
                    />
                    <Form.Control.Feedback type="invalid">{errors.price?.message}</Form.Control.Feedback>
                    <Form.Text className="text-muted">
                        <FormattedMessage id="SERVICE.PAYMENT.PRICE_DESCRIPTION" />
                    </Form.Text>
                </Form.Group>
                {pricePerUnits.length > 0 && (
                    <Form.Group as={Col} xl={2}>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.PAYMENT.PRICE_PER_UNIT" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="pricePerUnit"
                            defaultValue={null}
                            render={() => (
                                <div>
                                    <Typeahead
                                        id="typeahead-payment-pricePerUnit"
                                        labelKey={"name"}
                                        onChange={(pricePerUnits) =>
                                            setValue("pricePerUnit", pricePerUnits[0], { shouldValidate: true })
                                        }
                                        options={pricePerUnits.map((pricePerUnit) =>
                                            getPricePerUnitOption(pricePerUnit, intl)
                                        )}
                                        placeholder={intl.formatMessage({
                                            id: "SERVICE.PAYMENT.PRICE_PER_UNIT.PLACEHOLDER",
                                        })}
                                        selected={pricePerUnit ? [pricePerUnit] : []}
                                        filterBy={() => true}
                                        inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                    />
                                </div>
                            )}
                        />
                    </Form.Group>
                )}
                <Form.Group as={Col} xl={2}>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.PAYMENT.VAT" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="vat"
                        defaultValue={null}
                        render={() => (
                            <div>
                                <Typeahead
                                    id="typeahead-payment-vat"
                                    labelKey={"name"}
                                    onChange={(vats) => setValue("vat", vats[0], { shouldValidate: true })}
                                    options={
                                        !serviceOptions?.vats ? [] : serviceOptions.vats.map((vat) => getVatOption(vat))
                                    }
                                    placeholder={intl.formatMessage({
                                        id: "SERVICE.PAYMENT.VAT.PLACEHOLDER",
                                    })}
                                    selected={vat ? [vat] : []}
                                    filterBy={() => true}
                                    inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                />
                            </div>
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} xl={2}>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.PAYMENT.CURRENCY" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="currency"
                        defaultValue={null}
                        render={() => (
                            <div>
                                <Typeahead
                                    id="typeahead-payment-currency"
                                    labelKey={"name"}
                                    onChange={(currencies) =>
                                        setValue("currency", currencies[0], { shouldValidate: true })
                                    }
                                    options={
                                        !serviceOptions?.currencies
                                            ? []
                                            : serviceOptions.currencies.map((currency) => getCurrencyOption(currency))
                                    }
                                    placeholder={intl.formatMessage({
                                        id: "SERVICE.PAYMENT.CURRENCY.PLACEHOLDER",
                                    })}
                                    selected={currency ? [currency] : []}
                                    filterBy={() => true}
                                    inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                />
                            </div>
                        )}
                    />
                </Form.Group>
            </Form.Row>
            {paymentTypeMode === PAYMENT_TYPE_MODE_SUBSCRIPTION && (
                <>
                    <Form.Row>
                        <Form.Group as={Col} xl={4}>
                            <Form.Label>
                                <FormattedMessage id="SERVICE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_UNIT.TITLE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="subscriptionRecurringUnit"
                                defaultValue={null}
                                render={() => (
                                    <div>
                                        <Typeahead
                                            id="typeahead-subscription-recurring-unit"
                                            labelKey={"name"}
                                            onChange={(data) =>
                                                setValue("subscriptionRecurringUnit", data[0], {
                                                    shouldValidate: true,
                                                })
                                            }
                                            options={[
                                                getSubscriptionRecurringUnitOption(
                                                    SUBSCRIPTION_RECURRING_UNIT_MONTHLY,
                                                    intl
                                                ),
                                                getSubscriptionRecurringUnitOption(
                                                    SUBSCRIPTION_RECURRING_UNIT_QUARTERLY,
                                                    intl
                                                ),
                                                getSubscriptionRecurringUnitOption(
                                                    SUBSCRIPTION_RECURRING_UNIT_YEARLY,
                                                    intl
                                                ),
                                            ]}
                                            selected={subscriptionRecurringUnit ? [subscriptionRecurringUnit] : []}
                                            filterBy={() => true}
                                            inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                        />
                                    </div>
                                )}
                            />
                        </Form.Group>
                        <Form.Group as={Col} xl={4}>
                            <Form.Label>
                                <FormattedMessage id="SERVICE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_DAY_MODE.TITLE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="subscriptionRecurringDayMode"
                                defaultValue={SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING}
                                render={() => (
                                    <div>
                                        <Typeahead
                                            id="typeahead-subscription-recurring-day-mode"
                                            labelKey={"name"}
                                            onChange={(data) => {
                                                const recurringDayMode = data[0];
                                                setValue("subscriptionRecurringDayMode", recurringDayMode, {
                                                    shouldValidate: true,
                                                });

                                                if (
                                                    shouldRecurringDayOfMonthBeLocked(
                                                        subscriptionRecurringUnit.id,
                                                        recurringDayMode.id
                                                    )
                                                ) {
                                                    setValue("subscriptionRecurringDayOfMonth", 1);
                                                }
                                            }}
                                            options={[
                                                getSubscriptionRecurringDayModeOption(
                                                    SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING,
                                                    intl
                                                ),
                                                getSubscriptionRecurringDayModeOption(
                                                    SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH,
                                                    intl
                                                ),
                                            ]}
                                            selected={
                                                subscriptionRecurringDayMode ? [subscriptionRecurringDayMode] : []
                                            }
                                            filterBy={() => true}
                                            inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                        />
                                    </div>
                                )}
                            />
                        </Form.Group>
                        {subscriptionRecurringDayMode?.id === SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH && (
                            <Form.Group as={Col} xl={4}>
                                <Form.Label>
                                    <FormattedMessage id="SERVICE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_DAY_MODE.DAY_OF_MONTH.TITLE" />
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    className="form-control form-control-lg"
                                    name="subscriptionRecurringDayOfMonth"
                                    isInvalid={errors.subscriptionRecurringDayOfMonth}
                                    defaultValue={subscriptionRecurringDayOfMonth}
                                    disabled={shouldRecurringDayOfMonthBeLocked(
                                        subscriptionRecurringUnit.id,
                                        subscriptionRecurringDayMode.id
                                    )}
                                    onChange={(e) => {
                                        // ref={register} breaks this control in some cases so set value manually here
                                        const dayOfMonth = e.target.value || "" ? parseInt(e.target.value, 10) : "";
                                        setValue("subscriptionRecurringDayOfMonth", dayOfMonth);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.subscriptionRecurringDayOfMonth?.message}
                                </Form.Control.Feedback>
                                <Form.Text className="text-muted">
                                    {!shouldRecurringDayOfMonthBeLocked(
                                        subscriptionRecurringUnit.id,
                                        subscriptionRecurringDayMode.id
                                    ) ? (
                                        <FormattedMessage id="SERVICE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_DAY_MODE.DAY_OF_MONTH.DESCRIPTION.UNLOCKED" />
                                    ) : (
                                        <FormattedMessage id="SERVICE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_DAY_MODE.DAY_OF_MONTH.DESCRIPTION.LOCKED" />
                                    )}
                                </Form.Text>
                            </Form.Group>
                        )}
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.NOTICE_PERIOD.TITLE" />
                        </Form.Label>
                        <Form.Control
                            type="number"
                            className="form-control form-control-lg col-xl-2"
                            name="subscriptionNoticePeriodDays"
                            ref={register}
                            isInvalid={errors.subscriptionNoticePeriodDays}
                            defaultValue={service?.subscriptionNoticePeriodDays}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.subscriptionNoticePeriodDays?.message}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                            <FormattedMessage id="SERVICE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.NOTICE_PERIOD.DESCRIPTION" />
                        </Form.Text>
                    </Form.Group>
                </>
            )}
            {allowPricingTemplates && (
                <Form.Row>
                    <Form.Group as={Col} xl={4}>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.PAYMENT.PRICING_TEMPLATE.APPOINTMENT" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="pricingTemplate"
                            defaultValue={null}
                            render={() => (
                                <div>
                                    <Typeahead
                                        id="typeahead-payment-pricing-template"
                                        labelKey={"name"}
                                        onChange={(pricingTemplates) =>
                                            setValue("pricingTemplate", pricingTemplates[0], { shouldValidate: true })
                                        }
                                        options={allPricingTemplates.map((bt) => getPricingTemplateOption(bt))}
                                        selected={pricingTemplate ? [pricingTemplate] : []}
                                        filterBy={() => true}
                                        inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                    />
                                </div>
                            )}
                        />
                        <Form.Text className="text-muted">
                            <FormattedMessage id="SERVICE.PAYMENT.PRICING_TEMPLATE.APPOINTMENT.DESCRIPTION" />
                        </Form.Text>
                    </Form.Group>
                </Form.Row>
            )}
            {paymentMode && paymentMode !== PAYMENT_MODE_ON_ARRIVAL && (
                <Form.Row>
                    <Form.Group as={Col} xl={4}>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.PAYMENT.BILLING_TEMPLATE" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="billingTemplate"
                            defaultValue={null}
                            render={() => (
                                <div>
                                    <Typeahead
                                        id="typeahead-payment-billing-template"
                                        labelKey={"name"}
                                        onChange={(billingTemplates) =>
                                            setValue("billingTemplate", billingTemplates[0], { shouldValidate: true })
                                        }
                                        options={allBillingTemplates.map((bt) => getBillingTemplateOption(bt))}
                                        selected={billingTemplate ? [billingTemplate] : []}
                                        filterBy={() => true}
                                        inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                    />
                                </div>
                            )}
                        />
                    </Form.Group>
                </Form.Row>
            )}
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.PAYMENT.CUSTOMER_MESSAGE_MODE" />
                </Form.Label>
                <ModeSettingDropdown
                    name={"customerMessageMode"}
                    register={register}
                    includeDefaultGlobal={true}
                    colSize={"col-xl-4"}
                />
            </Form.Group>
            {(customerMessageMode === MODE_OPTIONAL || customerMessageMode === MODE_MANDATORY) && (
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.PAYMENT.CUSTOMER_MESSAGE" />
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        className="form-control form-control-lg col-xl-8"
                        name="customerMessage"
                        ref={register}
                        defaultValue={service?.customerMessage}
                    />
                </Form.Group>
            )}
            {allowAddonsMode && (
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.PAYMENT.ADDONS_MODE" />
                    </Form.Label>
                    <ModeSettingDropdown
                        name={"addonsMode"}
                        register={register}
                        includeDefaultGlobal={true}
                        colSize={"col-xl-4"}
                    />
                </Form.Group>
            )}
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.PAYMENT.PROXY_USERS_MODE" />
                </Form.Label>
                <ModeSettingDropdown
                    name={"proxyUsersMode"}
                    register={register}
                    includeDefaultGlobal={true}
                    colSize={"col-xl-4"}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage id="SERVICE.PAYMENT.PROXY_USERS_MODE_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.PAYMENT.EMAIL_DETAILS" />
                </Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    className="form-control form-control-lg col-xl-8"
                    name="emailDetails"
                    ref={register}
                    defaultValue={service?.emailDetails}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage
                        id="SERVICE.PAYMENT.EMAIL_DETAILS_DESCRIPTION"
                        values={{
                            p: (...chunks) => <p>{chunks}</p>,
                            a: (msg) => (
                                <Link to={`/settings/bookings`} key={1}>
                                    <FormattedMessage id={"PROFILE.BOOKING_SETTINGS.TITLE"} />
                                </Link>
                            ),
                        }}
                    />
                </Form.Text>
            </Form.Group>
        </div>
    );
}

export default connect(null, serviceCategoriesActions)(ServiceCheckoutDetailsForm);
