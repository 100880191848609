import React, { useEffect } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import ServiceCheckoutDetailsFormOld from "../ServiceCheckoutDetailsFormOld";
import ServiceCheckoutDetailsForm from "../ServiceCheckoutDetailsForm";
import {
    PAYMENT_MODE_ON_ARRIVAL,
    PAYMENT_MODE_OPTIONAL,
    PAYMENT_METHOD_SWISH,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_INVOICE,
    PAYMENT_TYPE_MODE_SUBSCRIPTION,
    SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH,
} from "../utils";

const schemaWithoutPaymentTemplate = yup.object().shape({
    paymentMode: yup.string(),
    paymentTypeMode: yup.string(),
    price: yup.number().when("paymentMode", (paymentMode) => {
        if (paymentMode === PAYMENT_MODE_ON_ARRIVAL || paymentMode === PAYMENT_MODE_OPTIONAL) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    paymentMethods: yup.array().when("paymentMode", (paymentMode) => {
        if (paymentMode !== PAYMENT_MODE_ON_ARRIVAL) {
            return yup.array().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD_CHECKBOXES" />);
        }

        return yup.array();
    }),
    vat: yup.object(),
    currency: yup.object(),
    billingTemplate: yup.object(),
    subscriptionRecurringDayMode: yup.object(),
    subscriptionRecurringDayOfMonth: yup
        .number()
        .when("subscriptionRecurringDayMode", (subscriptionRecurringDayMode) => {
            if (subscriptionRecurringDayMode?.id !== SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH) {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
            } else {
                return yup
                    .number()
                    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                    .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />)
                    .max(31, <FormattedMessage id="FORM.ERROR.MAX" values={{ number: 31 }} />);
            }
        }),
    subscriptionNoticePeriodDays: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
    customerMessageMode: yup.string(),
    addonsMode: yup.string(),
    proxyUsersMode: yup.string(),
    emailDetails: yup.string(),
});

const schemaWithPaymentTemplate = yup.object().shape({
    paymentMode: yup.string(),
    paymentTypeMode: yup.string(),
    price: yup.number().when("paymentMode", (paymentMode) => {
        if (paymentMode === PAYMENT_MODE_ON_ARRIVAL || paymentMode === PAYMENT_MODE_OPTIONAL) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    vat: yup.object(),
    currency: yup.object(),
    paymentTemplate: yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    customerMessageMode: yup.string(),
    addonsMode: yup.string(),
    proxyUsersMode: yup.string(),
    giftMode: yup.string(),
    emailDetails: yup.string(),
});

function VoucherPageCheckoutSettings({ updateVoucher, getServiceOptions }) {
    const { profile } = useSelector((state) => state.auth);
    const { voucher, serviceOptions, isUpdating } = useSelector((state) => state.services);

    const formMethods = useForm({
        resolver: yupResolver(
            serviceOptions?.paymentTemplates?.length > 0 ? schemaWithPaymentTemplate : schemaWithoutPaymentTemplate
        ),
    });

    useEffect(() => {
        getServiceOptions(profile.id);
    }, [profile.id, getServiceOptions]);

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalVoucher = { ...voucher };
        originalVoucher.currencyId = originalVoucher?.currency?.id;
        originalVoucher.vatId = originalVoucher?.vat?.id;
        originalVoucher.paymentTemplateId = originalVoucher?.paymentTemplate?.id || null;
        originalVoucher.billingTemplateId = originalVoucher?.billingTemplate?.id || null;
        originalVoucher.isSwishEnabled = originalVoucher.paymentMethods.includes(PAYMENT_METHOD_SWISH);
        originalVoucher.isCardEnabled = originalVoucher.paymentMethods.includes(PAYMENT_METHOD_CARD);
        originalVoucher.isInvoiceEnabled = originalVoucher.paymentMethods.includes(PAYMENT_METHOD_INVOICE);

        let updatedVoucherData = { ...voucher };
        updatedVoucherData.price = values.price;
        updatedVoucherData.currencyId = values?.currency?.id;
        updatedVoucherData.vatId = values?.vat?.id;
        updatedVoucherData.paymentTemplateId = values?.paymentTemplate?.id;
        updatedVoucherData.customerMessageMode = values.customerMessageMode || null;
        updatedVoucherData.customerMessage = values.customerMessage;
        updatedVoucherData.addonsMode = values.addonsMode || null;
        updatedVoucherData.proxyUsersMode = values.proxyUsersMode || null;
        updatedVoucherData.giftMode = values.giftMode || null;
        updatedVoucherData.emailDetails = values.emailDetails;

        if (serviceOptions.paymentTemplates.length === 0) {
            updatedVoucherData.paymentMode = values.paymentMode;
            updatedVoucherData.subscriptionRecurringUnit = values.subscriptionRecurringUnit?.id;
            updatedVoucherData.subscriptionRecurringDayMode = values.subscriptionRecurringDayMode?.id;
            updatedVoucherData.subscriptionRecurringDayOfMonth = values.subscriptionRecurringDayOfMonth;
            updatedVoucherData.subscriptionNoticePeriodDays = values.subscriptionNoticePeriodDays;
            updatedVoucherData.billingTemplateId = values?.billingTemplate?.id;

            if (values.paymentMethods) {
                updatedVoucherData.isSwishEnabled = values.paymentMethods.includes(PAYMENT_METHOD_SWISH);
                updatedVoucherData.isCardEnabled = values.paymentMethods.includes(PAYMENT_METHOD_CARD);
                updatedVoucherData.isInvoiceEnabled = values.paymentMethods.includes(PAYMENT_METHOD_INVOICE);
            }
        }

        updateVoucher(voucher.id, originalVoucher, updatedVoucherData);
    };

    const hasPaymentTemplates = serviceOptions?.paymentTemplates?.length > 0;

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.VOUCHER.PAYMENT_SETTINGS.TITLE" />
                        </h3>
                    </div>
                    {voucher && !voucher?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            {serviceOptions && !hasPaymentTemplates && (
                                <ServiceCheckoutDetailsFormOld
                                    serviceOptions={serviceOptions}
                                    service={voucher}
                                    pricePerOccasion={false}
                                    allowSubscription={voucher?.paymentTypeMode === PAYMENT_TYPE_MODE_SUBSCRIPTION}
                                    forceSubscription={voucher?.paymentTypeMode === PAYMENT_TYPE_MODE_SUBSCRIPTION}
                                />
                            )}
                            {serviceOptions && hasPaymentTemplates && (
                                <ServiceCheckoutDetailsForm
                                    serviceOptions={serviceOptions}
                                    service={voucher}
                                    pricePerOccasion={false}
                                />
                            )}
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, serviceActions)(VoucherPageCheckoutSettings);
